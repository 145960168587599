import * as types from '../constants/actionTypes';

export const addLead = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.LEAD_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/leads`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const getLeads = (page, limit, month = null, year = null) => (dispatch, getState) => {
  const params = {
    page,
    limit,
    month,
    year
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.LEAD_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/leads?include=installer`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const getLead = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.LEAD_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/leads/${id}?include=installer,service`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  }
});

export const editLead = (id, data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.LEAD_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/leads/${id}`,
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const deleteLead = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.LEAD_DELETE_ITEM, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/leads/${id}`,
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  },
  id: id
});
